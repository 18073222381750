import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Player.scss'
import { useTabs } from '@/Hooks/useTabs'
import { deleteTrack, saveTrack } from '@/Services/Spotify/TracksService'
import { usePlayer } from '@/Hooks/usePlayer'
import { useDispatch } from 'react-redux'
import { updateSaved } from '@/Redux/PlayerSlice'
import { seekPosition } from '@/Services/Spotify/SpotifyService'

export default function PlayerPage() {
    const { openArtistTab } = useTabs()
    const { track, artist, playing, start, playPreviousTrack, playNextTrack } = usePlayer()
    const dispatch = useDispatch()

    const saveDeleteTrack = async (id: string) => {
        (!track.saved) ? await saveTrack(id) : await deleteTrack(id)
        dispatch(updateSaved())
    }
    
    return (
        <div className='player-page'>
            <div className='player-data'>
                <h3>{track.name}</h3>
                <span onClick={() => openArtistTab(artist.id, artist.name)}>{artist.name}</span>
            </div>

            <div className='player'>
                <button className='b-m' onClick={playPreviousTrack}>
                    <FontAwesomeIcon icon="backward" />
                </button>
                <button className='b-l' onClick={() => seekPosition(0)}>
                    <FontAwesomeIcon icon="backward-step" />
                </button>
                <button className='b-xl' onClick={start}>
                    { playing ? <FontAwesomeIcon icon="pause" /> : <FontAwesomeIcon icon="play" />}
                </button>
                <button className='b-l' onClick={playNextTrack}>
                    <FontAwesomeIcon icon="forward-step" />
                </button>
                <button className={track.saved ? 'b-m saved' : 'b-m'} onClick={() => saveDeleteTrack(track.id)}>
                    <FontAwesomeIcon icon="heart" />
                </button>
            </div>
        </div>
    )
}