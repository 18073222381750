import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import './Sidebar.scss'
import { faDocker } from '@fortawesome/free-brands-svg-icons'
import { useTabs } from "@/Hooks/useTabs"
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons"
import { usePlayer } from "@/Hooks/usePlayer"

export default function Sidebar() {
    const { openSearchTab, openHomeTab, openHistoryTab, openSavedTab } = useTabs();
    const { playing, start } = usePlayer()

    return (
        <div className="sidebar">
            <div className="sidebar-icons">
                <span onClick={openHomeTab}><FontAwesomeIcon icon="file"/></span>
                <span onClick={openSearchTab}><FontAwesomeIcon icon="magnifying-glass"/></span>
                <span onClick={openHistoryTab}><FontAwesomeIcon icon="code-branch" /></span>
                <span onClick={start}><FontAwesomeIcon icon={playing ? "pause" : "play"}/></span>
                <span onClick={openHistoryTab}><FontAwesomeIcon icon={faClockRotateLeft} /></span>
                <span onClick={openSavedTab}><FontAwesomeIcon icon="cube" /></span>
                <span><FontAwesomeIcon icon={faDocker} /></span>
            </div>
        </div>
    )
}