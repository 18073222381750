import { useTabs } from '@/Hooks/useTabs';
import './Search.scss'
import { useSearch } from '@/Hooks/useSearch'
import { playAlbum, playTrack } from '@/Services/Spotify/SpotifyService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@/Redux/Store';
import { tabSelector } from '@/Redux/TabsSlice';
import { useEffect } from 'react';

export default function SearchPage() {
    let { id: tabId } = useParams();
   
    const { handleSearch, searchQuery, setSearchQuery, setArtists, setAlbums, setTracks, artists, albums, tracks } = useSearch(tabId!)

    const tab = useAppSelector((state) => tabSelector(state, tabId!))
   
    useEffect(() => {
        setArtists(tab?.search?.artists ?? [])
        setAlbums(tab?.search?.albums ?? [])
        setTracks(tab?.search?.tracks ?? [])
        setSearchQuery(tab?.search?.searchQuery ?? '')
    }, [tabId, tab, setSearchQuery, setArtists, setAlbums, setTracks]) 

    const { openArtistTab } = useTabs();

    return (
        <div className="searchPage" key={'tab-' + tabId}>
            <form onSubmit={handleSearch}>
                <div className="searchForm">
                    <input type="text" value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} />
                    <button type="submit">Buscar</button>
                </div>
            </form>

            <div className='results'>
                <section>
                    <h3>Artistas</h3>
                    {artists?.map(({ id, name, image }) =>
                        <div className="artist" key={'artist-' + id} onClick={() => openArtistTab(id, name)}>
                           <div>
                                <img src={image} alt={name}></img>
                                <span>{name}</span>
                           </div>
                           <div>

                           </div>
                        </div>
                    )}
                </section>
                <section>
                    <h3>Albums</h3>
                    {albums?.map(({ id, name, image, uri }) =>
                        <div className="album" key={'album-' + id}>
                            <div>
                                <img src={image} alt={name}></img>
                                <span>{name}</span>
                            </div>
                            <span className="play" onClick={() => playAlbum(uri, 0)}>
                                <FontAwesomeIcon icon="play" />
                            </span>
                        </div>
                    )}
                </section>
                <section>
                    <h3>Canciones</h3>
                    {tracks?.map(({ id, name, image, uri }) =>
                        <div className="track" key={'track-' + id}>
                            <div>
                                <img src={image} alt={name}></img>
                                <span>{name}</span>
                            </div>
                            <span className="play" onClick={() => playTrack(uri)}>
                                <FontAwesomeIcon icon="play" />
                            </span>
                        </div>
                    )}
                </section>
            </div>
        </div>
    )
}