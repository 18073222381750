import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './TabsNavbar.scss'
import { useSelector, useDispatch } from "react-redux";
import { Tab } from '@/Interfaces/Tabs';
import { selectTab } from "@/Redux/TabsSlice";
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTabs } from '@/Hooks/useTabs';

const TabsNavbar = () => {
  const tabs = useSelector((state: any) => state.tabs)
  const dispatch = useDispatch()
  const { deleteTabAndChangeRoute } = useTabs()

  return (
    <nav className="tabs-navbar">
      <ul className="tabs-navbar-list">
        {
          tabs.tabs.map(({ route, id, image, icon, name }: Tab) =>
            <li className={id === tabs.activeTabId ? 'active' : ''} key={id} onAuxClick={(event) => { event.preventDefault(); deleteTabAndChangeRoute(id) }}>
              <Link key={id} to={`${route}/${id}`} onClick={() => dispatch(selectTab(id))}>
                {image
                  ? <img alt="" src={image}></img>
                  : <FontAwesomeIcon icon={icon as IconProp} />
                }
                <span>{name}</span>
              </Link>

              <FontAwesomeIcon icon="xmark" onClick={() => deleteTabAndChangeRoute(id)} />
            </li>
          )
        }
      </ul>
    </nav>
  )
}

export default TabsNavbar