import { useEffect, useState } from "react"
import { NoData } from "@/Services/ApiService"
import { Album } from "@/Interfaces/Common"
import { getArtistAlbums } from "@/Services/Spotify/ArtistsService"
import { ArtistAlbumsResponse } from "@/Interfaces/ArtistAlbums"
import { useAppSelector } from "@/Redux/Store"
import { tabSelector } from "@/Redux/TabsSlice"

export const useArtistAlbums = (id: string) => {
    const [artistAlbums, setArtistAlbums] = useState<Album[]>()
    const tab = useAppSelector((state) => tabSelector(state, id))

    useEffect(() => {
        if (!tab || !tab.artist) return

        getArtistAlbums(tab.artist.id!).then((response: ArtistAlbumsResponse) => {
            if (NoData(response)) return
            setArtistAlbums(response.albums)
        })
    }, [id, tab])

    return { artistAlbums, tab }
}