import { useState } from 'react'
import './Home.scss'
import { setVolumePercent } from '@/Services/Spotify/SpotifyService'

export default function HomePage() {
    const [volume, setVolume] = useState<number>(0)

    const onWheel = async (event: any) => {
        if (event.deltaY < 0)
            setVolume(volume + 5)
        else
            setVolume(volume - 5)

        setVolumePercent(volume)
    };

    return (
        <div className="home" onWheel={onWheel}></div>
    )
}