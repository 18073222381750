import { useEffect, useState } from "react"
import { NoData } from "@/Services/ApiService"
import { Album } from "@/Interfaces/Common"
import { getRecentlyPlayedTracks } from "@/Services/Spotify/TracksService"
import { RecentlyPlayedTracksResponse } from "@/Interfaces/RecentlyPlayedTracks"

export const useRecentlyPlayedTracks = (id: string) => {
    const [recentlyPlayedTracks, setRecentlyPlayedTracks] = useState<Album[]>()

    useEffect(() => {
        getRecentlyPlayedTracks().then((response: RecentlyPlayedTracksResponse) => {
            if (NoData(response)) return
            setRecentlyPlayedTracks(response.tracks)
        })
    }, [id])

    return { recentlyPlayedTracks }
}