import { createSelector, createSlice } from "@reduxjs/toolkit"
import { Tab, TabTypes } from "@/Interfaces/Tabs"
import { newUuID } from "@/Services/ApiService";
import { RootState } from "./Store";

const homeUuID = newUuID()

const initialState: { tabs: Tab[], activeTabId: string, previousTabsId: string[] } = {
    tabs: [
        {
            id: homeUuID,
            name: 'Inicio',
            icon: 'home',
            route: 'home',
            type: TabTypes.Home,
            image: null,
        },
        {
            id: newUuID(),
            name: 'Reproductor',
            icon: 'music',
            route: 'player',
            type: TabTypes.Player,
            image: null,
        }
    ],
    activeTabId: homeUuID,
    previousTabsId: [],
}

export const tabSlice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        addTab: (state, action) => {
            const { id } = action.payload;
            return { ...state, tabs: [...state.tabs, action.payload], activeTabId: id, previousTabsId: [...state.previousTabsId, id] }
        },
        deleteTab: (state, action) => {
            const tabs = state.tabs.filter(tab => tab.id !== action.payload)
            
            let previousTabsId = state.previousTabsId.filter(x => x !== action.payload)
            let activeTabId = state.activeTabId

            if (action.payload === state.activeTabId)
                activeTabId = previousTabsId.at(-1) ?? state.activeTabId
  
            return { ...state, tabs: tabs, activeTabId, previousTabsId }
        },
        selectTab: (state, action) => {
            return { ...state, previousTabsId: [...state.previousTabsId, state.activeTabId], activeTabId: action.payload }
        },
        updateTabArtist: (state, action) => {
            const { id, artist } = action.payload

            const tabs = state.tabs.map(tab => {
                if (tab.id === id) return { ...tab, artist }
                return tab
            })

            return { ...state, tabs }
        },
        updateTabSearch: (state, action) => {
            const { id, search } = action.payload

            const tabs = state.tabs.map(tab => {
                if (tab.id === id) { return { ...tab, search }}
                return tab
            })

            return { ...state, tabs }
        }
    },
});

const items = (state: RootState) => state.tabs

export const tabsSelector = createSelector([items], (items) => items.tabs)
export const tabSelector = createSelector([items, (items, id: string) => id], (items, id) => items.tabs.find(tab => tab.id === id))
export const previousTabRouteSelector = createSelector([items], (items) => {
    const peviousTab = items.tabs.find(tab => tab.id === items.previousTabsId.at(-1))
    return peviousTab ? peviousTab.route + '/' + peviousTab.id : ''
})

export const { addTab, deleteTab, selectTab, updateTabArtist, updateTabSearch } = tabSlice.actions
export default tabSlice.reducer