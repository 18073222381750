import { CurrentlyPlayingResponse } from "@/Interfaces/CurrentlyPlaying"
import { QueueResponse } from "@/Interfaces/PlayerQueue"
import { getApi, postApi, sendGetApi } from "../ApiService"

export const getCurrenlyPlaying = async (): Promise<CurrentlyPlayingResponse> => {
    try {
        return await getApi('spotify/player/currently-playing')
    } catch (error) {
        throw new Error()
    }
}

export const getQueue = async (): Promise<QueueResponse> => {
    try {
        return await getApi('spotify/player/queue')
    } catch (error) {
        throw new Error()
    }
}

export const playNext = async () => {
    await sendGetApi('spotify/player/next')
}

export const playPrevious = async () => {
    await sendGetApi('spotify/player/previous')
}

export const seekPosition = async (position: number) => {
    await postApi('spotify/player/seek-position', { position })
}

export const addToQueue = async (uri: string): Promise<void> => {
    await postApi('spotify/player/add', { uri })
    await playNext()
}

export const playTrack = async (uri: string): Promise<void> => {
    await postApi('spotify/player/play/track', { uri })
}

export const playAlbumTrack = async (albumUri: string, trackUri: string): Promise<void> => {
    await postApi('spotify/player/play/album/track', { albumUri, trackUri })
}

export const playAlbum = async (albumUri: string, position: number): Promise<void> => {
    await postApi('spotify/player/play/album', { albumUri, position })
}

export const setVolumePercent = async (volumePercent: number): Promise<void> => {
    await postApi('spotify/player/set-volume', { volumePercent })
}