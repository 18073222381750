import { useDispatch, useStore } from "react-redux";
import { addTab, deleteTab } from "@/Redux/TabsSlice";
import { useNavigate } from "react-router-dom";
import { newUuID } from "@/Services/ApiService";
import { getArtistById } from "@/Services/Spotify/ArtistsService";
import { Tab, TabTypes } from "@/Interfaces/Tabs";
import { RootState } from "@/Redux/Store";

export const useTabs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const store = useStore<RootState>();
    
    const openArtistTab = async (id: string, artistName: string) => {
        const { artist }= await getArtistById(id)

        const tab = {
            id: newUuID(),
            name: artistName,
            image: artist.image,
            route: 'artist',
            type: TabTypes.Artist,
            artist,
        }

        openTab(tab)
    }

    const openSearchTab = () => {
        const tab = {
            id: newUuID(),
            name: 'Buscar',
            icon: 'magnifying-glass',
            route: 'search',
            type: TabTypes.Search,
        }

        openTab(tab)
    }

    const openHomeTab = () => {
        const tab = {
            id: newUuID(),
            name: 'Inicio',
            icon: 'home',
            route: 'home',
            type: TabTypes.Home,
        }

        openTab(tab)
    }

    const openHistoryTab = () => {
        const tab = {
            id: newUuID(),
            name: 'Historial',
            icon: 'clock-rotate-left',
            route: 'history',
            type: TabTypes.History,
        }

        openTab(tab)
    }

    const openPlayerTab = () => {
        const tab = {
            id: newUuID(),
            name: 'Reproductor',
            icon: 'music',
            route: 'player',
            type: TabTypes.Player,
        }

        openTab(tab)
    }

    const openCategoriesTab = () => {
        const tab = {
            id: newUuID(),
            name: 'Categorías',
            icon: 'elementor',
            route: 'categories',
            type: TabTypes.Categories,
        }

        openTab(tab)
    }

    const openSavedTab = () => {
        const tab = {
            id: newUuID(),
            name: 'Guardado',
            icon: 'cube',
            route: 'saved',
            type: TabTypes.Saved,
        }

        openTab(tab)
    }

    const openTab = (tab: any) => {
        dispatch(addTab(tab))
        navigate(`/${tab.route}/${tab.id}`)
    }

    const deleteTabAndChangeRoute = (id: string) => {
        dispatch(deleteTab(id))
        const { tabs, previousTabsId } = store.getState().tabs

        const previousTabId = previousTabsId.at(-1)
        const previousTab = tabs.find((tab: Tab) => tab.id === previousTabId)

        if (previousTab) 
            navigate(previousTab.route + '/' + previousTab.id)
    }

    return { openArtistTab, openHomeTab, openSearchTab, openHistoryTab, deleteTabAndChangeRoute, openSavedTab, openPlayerTab, openCategoriesTab }
}