import { useState } from "react"
import { useNavigate } from 'react-router-dom'

import { postLogin } from "@/Services/User/UserService"
import { newUuID } from "@/Services/ApiService"

export const useLogin = () => {
    const navigate = useNavigate()

    const [loggedUser, setLoggedUser] = useState<string>()

    const onLogin = async (username: string, password: string) => {
        postLogin({username, password}).then((token : string) => 
        {
            localStorage.setItem('token', token)
            setLoggedUser(token)
            navigate('/home/'+ newUuID())
        })
    }

    return { loggedUser, onLogin }
}