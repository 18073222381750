import { useDispatch } from "react-redux"
import { PlayerState, getCurrenlyPlayingTrack, playerSelector, playerStartStop } from "@/Redux/PlayerSlice"
import { AppDispatch, useAppSelector } from "@/Redux/Store"
import { playNext, playPrevious } from '@/Services/Spotify/SpotifyService'

export const usePlayer = () => {
    const dispatch = useDispatch<AppDispatch>();
    const player = useAppSelector((state) => playerSelector(state))

    const start = async () => {
        dispatch(playerStartStop()).then(() => {
            dispatch(getCurrenlyPlayingTrack())
        })
    }

    const playPreviousTrack = async () => {
        await playPrevious()
        setTimeout(() => {
            dispatch(getCurrenlyPlayingTrack())
        }, 250);
    }

    const playNextTrack = async () => {
        await playNext()
        setTimeout(() => {
            dispatch(getCurrenlyPlayingTrack())
        }, 250);
    }

    if (!player || !player?.loaded) return { 
        ...{} as PlayerState, start, playPreviousTrack, playNextTrack, loaded: false 
    }
    
    const { track, artist, playing } = player
    document.title = `${artist.name} - ${track.name}`

    return { track, artist, playing, start, loaded: true, playPreviousTrack, playNextTrack }
}
