import { useEffect, useState } from "react"
import { NoData } from "@/Services/ApiService"
import { Album } from "@/Interfaces/Common"
import { getUserSavedTracks } from "@/Services/Spotify/TracksService"
import { UserSavedTracksResponse } from "@/Interfaces/UserSavedTracks"

export const useSavedTracks = (id: string) => {
    const [savedTracks, setSavedTracks] = useState<Album[]>()

    useEffect(() => {
        getUserSavedTracks().then((response: UserSavedTracksResponse) => {
            if (NoData(response)) return
            setSavedTracks(response.savedTracks.map(savedTrack => savedTrack.track))
        })
    }, [id])

    return { savedTracks }
}