import { useState } from "react"
import { NoData } from "@/Services/ApiService"
import { Album, Artist, Track } from "@/Interfaces/Common"
import { SearchResponse } from "@/Interfaces/Search"
import { search } from "@/Services/Spotify/SearchService"
import { useDispatch } from "react-redux"
import { updateTabSearch } from "@/Redux/TabsSlice"

export const useSearch = (id: string) => {
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState<string>('')
    const [artists, setArtists] = useState<Artist[]>([])
    const [albums, setAlbums] = useState<Album[]>([])
    const [tracks, setTracks] = useState<Track[]>([])

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (searchQuery == null) return

        search(searchQuery, ['track', 'artist', 'album']).then(
            (response: SearchResponse) => {
                if (NoData(response)) return
                
                const { artists, albums, tracks } = response;
                setArtists(artists)
                setAlbums(albums)
                setTracks(tracks)

                dispatch(updateTabSearch({id, search: {artists, albums, tracks, searchQuery}}))
            }
        )
    }

    return { artists, albums, tracks, setSearchQuery, setArtists, setAlbums, setTracks, searchQuery, handleSearch }
}