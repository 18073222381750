import { RecentlyPlayedTracksResponse } from "@/Interfaces/RecentlyPlayedTracks"
import { getApi, postApi } from "../ApiService"
import { UserSavedTracksResponse } from "@/Interfaces/UserSavedTracks"

export const saveTrack = async (id: string): Promise<void> => {
    await postApi('spotify/tracks/save', { ids: [id] })
}

export const deleteTrack = async (id: string): Promise<void> => {
    await postApi('spotify/tracks/saved/delete', { ids: [id] })
}

export const getUserSavedTracks = async (): Promise<UserSavedTracksResponse> => {
    return getApi('spotify/tracks/saved').then(resp => {
        return resp;
    }).catch(x => x)
}

export const getRecentlyPlayedTracks = async (): Promise<RecentlyPlayedTracksResponse> => {
    return getApi('spotify/player/recently-played').then(resp => {
        return resp;
    }).catch(x => x)
}

