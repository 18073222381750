import { ArtistProfile } from "./Artist";
import { SearchResponse } from "./Search";

export interface Tab {
    id: string,
    name: string,
    type: TabTypes,
    icon: string,
    image: string | null,
    route: string,
    artist?: ArtistProfile,
    search?: SearchResponse
    // album?: Album
}

export enum TabTypes {
    Home = 1,
    Search = 2,
    History = 3,
    Artist = 4,
    Album = 5,
    Track = 6,
    Saved = 7,
    Player = 8,
    Categories = 9,
}