import { useCurrentlyPlaying } from "@/Hooks/useCurrentlyPlaying"
import { usePlayer } from "@/Hooks/usePlayer"
import { useTabs } from "@/Hooks/useTabs"
import { deleteTrack, saveTrack } from "@/Services/Spotify/TracksService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ExplorerCurrentlyPlaying(): any {
    useCurrentlyPlaying()

    const saveDeleteTrack = async (id: string, saved: boolean) => {
        (!saved) ? await saveTrack(id) : await deleteTrack(id)
    }

    const { openArtistTab, openPlayerTab } = useTabs()
    const { loaded, track, artist } = usePlayer()
   
    if (!loaded) return <span className="no-explorer-data">
                            No hay nada reproduciendo
                        </span>

    return <div className="explorer-playing-song" key={track.id}>
        <div className="explorer-playing-song-left">
            <img src={track.image} alt="" onClick={() => openPlayerTab()}></img>
            <div>
                <span>{track.name}</span>
                <small onClick={() => openArtistTab(artist.id, artist.name)}>{artist.name}</small>
            </div>
        </div>
        <div>
            <FontAwesomeIcon icon="heart"
                className={track.saved ? 'saved' : ''}
                onClick={() => saveDeleteTrack(track.id, track.saved)} />
        </div>
    </div>
}