import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import Layout from "./Common/Layout/Layout"
import LoginPage from './Pages/Login/LoginPage'
import HomePage from './Pages/Home/HomePage'
import { useLogin } from "./Hooks/useLogin"
import SearchPage from "./Pages/Search/SearchPage"
import ArtistPage from "./Pages/Artist/ArtistPage"
import AlbumPage from "./Pages/Album/AlbumPage"
import HistoryPage from "./Pages/History/HistoryPage"
import SavedPage from "./Pages/Saved/Saved"
import PlayerPage from "./Pages/Player/Player"
import CategoriesPage from "./Pages/Categories/Categories"

export const AppRouter: React.FC<{}> = () => {
    const { onLogin, loggedUser } = useLogin();

    return (
        <Routes>
            <Route path="/login" element={<LoginPage onLogin={onLogin} />} />
            <Route path="/" element={loggedUser ? <Layout /> : <Navigate to="/login" />}>
                <Route path="/home/:id" element={<HomePage />} />
                <Route path="/search/:id" element={<SearchPage />} />
                <Route path="/history/:id" element={<HistoryPage />} />
                <Route path="/artist/:id" element={<ArtistPage />} />
                <Route path="/album/:id" element={<AlbumPage />} />
                <Route path="/saved/:id" element={<SavedPage />} />
                <Route path="/player/:id" element={<PlayerPage />} />
                <Route path="/categories/:id" element={<CategoriesPage />} />
                <Route path="*" element={<div>Nada que mostrar</div>} />
            </Route>
        </Routes>
    )
}