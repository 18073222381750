import { useEffect, useState } from "react"
import { Track } from "@/Interfaces/Common"
import { NoData } from "@/Services/ApiService"
import { getQueue } from "@/Services/Spotify/SpotifyService"

export const useQueue = () => {
    const [tracks, setTracks] = useState<Track[]>()

    const getUpdatedQueue = async () => {
        if (document.hidden) return
        const queue = await getQueue()
        if (NoData(queue)) return
        setTracks(queue.tracks)
    }

    useEffect(() => {
        const interval = setInterval(async () => getUpdatedQueue(), 10000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        getUpdatedQueue()
    }, [])

    return { tracks }
}