import { useParams } from 'react-router-dom';
import { useArtistAlbums } from '@/Hooks/useArtistAlbums';
import { Album } from '@/Interfaces/Common';
import { Tab } from '@/Interfaces/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { playAlbum } from '@/Services/Spotify/SpotifyService';

export default function ArtistPage() {
    let { id: tabId } = useParams();

    const { tab, artistAlbums }: { tab?: Tab, artistAlbums?: Album[] } = useArtistAlbums(tabId!)
    if (!tab || !tab.artist) return <div></div>

    const {
        name: artistName, image: artistImage,
        followers, genres
    } = tab.artist

    return (
        <div key={'tab-' + tabId}>
            <section>
                <div className='artist'>
                    <div className="artist-header">
                        <img src={artistImage} alt={artistName}></img>
                        <div className="artist-header-data">
                            <h2>{artistName}</h2>
                            <p>{followers} seguidores</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-2">
                <h3>Géneros</h3>
                <p>{genres}</p>
            </section>

            <section>
                <h3>Albumes</h3>
                <div className="artist-albums">
                    {artistAlbums?.map(({ id: albumId, name, image, uri }: Album) =>
                        <div className="artist-album" key={'artist-album-' + albumId}>
                            <div>
                                <img src={image} alt={name}></img>
                                <span>{name.slice(0, 20)}</span>
                            </div>
                            <div className="play" onClick={() => playAlbum(uri, 0)}>
                                <FontAwesomeIcon icon="play" />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}