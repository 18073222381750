import { Track } from "@/Interfaces/Common"
import { addToQueue, playAlbumTrack } from "@/Services/Spotify/SpotifyService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface Props {
    tracks: Track[]
}

export default function ExplorerTracks({ tracks }: Props): any {
    return tracks?.map((track: Track, index: number) =>
        <div className="explorer-song" key={'queue-track-' + track.id + '-' + index}>
            <div>
                <img src={track.image} alt=""></img>
                <span>{track.name.slice(0, 20)}</span>
            </div>
            <div>
                <span className="play" onClick={() => playAlbumTrack(track.album.uri, track.uri)} onAuxClick={() => addToQueue(track.uri)}>
                    <FontAwesomeIcon icon="play" />
                </span>
            </div>
        </div>
    );
}