import { Outlet } from "react-router-dom"
import Sidebar from "./components/Sidebar/Sidebar"
import Explorer from "./components/Explorer/Explorer"
import NavBar from "./components/Navbar/Navbar"
import TabsNavbar from "./components/TabsNavbar/TabsNavbar"

export default function Layout() : any {

  return (
    <>
      <NavBar />
      <TabsNavbar />
      <Sidebar/>
      <Explorer/>

      <div className="outlet">
        <Outlet />
      </div>
    </>
  )
}