import { backendUrl } from "../ApiService"

export const postLogin = async ({ username, password }: any): Promise<any> => {
    try {
        const response = await fetch(`${backendUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        })
        
        const { token } = await response.json()
        return token
    } catch (error) {
        throw new Error()
    }
}