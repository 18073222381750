import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Navbar.scss'
import { usePlayer } from '@/Hooks/usePlayer'

const resizeWindow = (width: number, height: number) => {
  window.resizeTo(window.screen.width - width, window.screen.height - height)
}

const Navbar = () => {
  const { playPreviousTrack, playNextTrack } = usePlayer()

  return (
    <>
      <nav className="navbar">
        <ul className="navbar-list">
          <li>Elemento</li>
          <li>Favorito</li>
          <li>Ver</li>
          <li onClick={playPreviousTrack}>Anterior</li>
          <li onClick={playNextTrack}>Siguiente</li>
          <li>Terminal</li>
        </ul>
      </nav>
      <nav className="navbar-center">
        <ul className="navbar-list-center">
          <li onClick={() => resizeWindow(1200, 600)}>
            <FontAwesomeIcon icon='table-list' />
          </li>
          <li onClick={() => resizeWindow(800, 300)}>
            <FontAwesomeIcon icon='table-columns' />
          </li>
          <li onClick={() => resizeWindow(0, 0)}>
            <FontAwesomeIcon icon='table-cells-large' />
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Navbar