import './History.scss'
import { playAlbum, playTrack } from '@/Services/Spotify/SpotifyService';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecentlyPlayedTracks } from '@/Hooks/useRecentlyPlayedTracks';

export default function HistoryPage() {
    let { id: tabId } = useParams();

    const { recentlyPlayedTracks } = useRecentlyPlayedTracks(tabId!);

    return (
        <main className="historyPage" key={'tab-' + tabId}>
            <section className="tracks">
                <h3>Canciones</h3>
                <table>
                    <tr>
                        <th>Nombre</th>
                        <th>Album</th>
                        <th>Artistas</th>
                        <th></th>
                    </tr>
                    {recentlyPlayedTracks?.map(({ id, name, image, uri }) =>
                        <tr className="track" key={'track-' + id} onClick={() => playTrack(uri)}>
                            <td className="title">
                                <img src={image} alt={name}></img>
                                <span>{name}</span>
                            </td>
                            <td>
                                <span>Nombre album</span>
                            </td>
                            <td>
                               <span>Artistas</span>
                            </td>
                            <td>
                                <span className="play" onClick={() => playAlbum(uri, 0)}>
                                    <FontAwesomeIcon icon="play" />
                                </span>
                            </td>
                        </tr>
                    )}
                </table>
            </section>  
        </main>
    );
}