import './Explorer.scss'

import { useQueue } from '@/Hooks/useQueue'
import ExplorerCurrentlyPlaying from './components/ExplorerCurrentlyPlaying';
import ExplorerTracks from './components/ExplorerTracks';

export default function Explorer() {
    const { tracks } = useQueue();

    return (
        <div className="explorer">
            <div className="explorer-header">
                <span>Explorador</span>
            </div>
            <section>
                <span className="section-title">Reproduciendo</span>
                <ExplorerCurrentlyPlaying/>
            </section>
            <section>
                <span className="section-title">Cola</span>
                <div className="explorer-songs">
                    { tracks && tracks.length > 0 
                        ? <ExplorerTracks tracks={tracks}/>
                        : <span className="no-explorer-data">No hay nada en cola</span>
                    }
                </div>
            </section>
        </div>
    )
}