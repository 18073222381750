import { useEffect } from "react"
import { getCurrenlyPlayingTrack } from "@/Redux/PlayerSlice"
import { useDispatch } from "react-redux"
import { AppDispatch } from "@/Redux/Store"

export const useCurrentlyPlaying = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getCurrenlyPlayingTrack())
    }, [dispatch])

    useEffect(() => {
        const interval = setInterval(() => {
            if (document.hidden) 
            dispatch(getCurrenlyPlayingTrack())
        }, 20000)
        return () => clearInterval(interval)
    }, [dispatch])

    useEffect(() => {
        const interval = setInterval(() => {
            if (!document.hidden) 
                dispatch(getCurrenlyPlayingTrack())
        }, 5000)
        return () => clearInterval(interval)
    }, [dispatch])

    return { }
}

