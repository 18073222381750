import { ArtistAlbumsResponse } from "@/Interfaces/ArtistAlbums"
import { getApi } from "../ApiService"
import { ArtistProfileResponse } from "@/Interfaces/Artist"

export const getArtistById = async (id: string): Promise<ArtistProfileResponse> => {
    try {
        return await getApi(`spotify/artists/${id}`)
    } catch (error) {
        throw new Error()
    }
}

export const getArtistAlbums = async (id: string): Promise<ArtistAlbumsResponse> => {
    try {
        return await getApi(`spotify/artists/${id}/albums`)
    } catch (error) {
        throw new Error()
    }
}
