export const backendUrl = 'https://api.clipot.alphapro.es'
// export const backendUrl = 'http://localhost:3000'

export const getApi = async (url: string): Promise<any> => {
  try {
    const resp = await fetch(`${backendUrl}/${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
    if (!resp || !resp.ok) throw new Error()
    return await resp.json()

  } catch (error) {
    throw new Error()
  }
}

export const sendGetApi = async (url: string): Promise<any> => {
  try {
    await fetch(`${backendUrl}/${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
  } catch (error) {
    throw new Error()
  }
}

export const postApi = async (uri: string, body: object) => {
  try {
    const resp = await fetch(`${backendUrl}/${uri}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })

    if (!resp || !resp.ok) throw new Error()
    return await resp.json()
  } catch (error) {
    throw new Error()
  }
}

export const NoData = (data: object) => {
  return (!data || Object.keys(data).length === 0)
}

export const newUuID = () => {
  var dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
  function( c ) {
     var rnd = Math.random() * 16;//random number in range 0 to 16
     rnd = (dt + rnd)%16 | 0;
     dt = Math.floor(dt/16);
     return (c === 'x' ? rnd : ((rnd & 0x3) | 0x8)).toString(16);
  });
}