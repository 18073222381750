import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit"
import { Artist, Track } from "@/Interfaces/Common"
import { NoData, sendGetApi } from "@/Services/ApiService"
import { getCurrenlyPlaying } from "@/Services/Spotify/SpotifyService"
import { CurrentlyPlayingResponse } from "@/Interfaces/CurrentlyPlaying"
import { RootState } from "./Store"

export interface PlayerState {
    playing: boolean,
    progress: number,
    track: Track,
    artist: Artist,
    loaded: boolean
}

const initialState: { player: PlayerState } = {
    player: {} as PlayerState
}

const playerStartStop = createAsyncThunk(
    'player/startStop',
    async (arg, { getState }: any) => {
        const state = getState().player.player
        return await sendGetApi(`spotify/player/${state.playing ? 'pause': 'start'}`)
    }
)

const getCurrenlyPlayingTrack = createAsyncThunk(
    'player/getCurrenltyPlaying',
    async () => {
        const response = await getCurrenlyPlaying()
        if (NoData(response)) return
        return response
    }
)

export const playerSlice = createSlice({
    name: 'players',
    initialState,
    reducers: {
        updateSaved: (state) => {
            state.player.track.saved = !state.player.track.saved
        },
    },
    extraReducers: (builder) => {
        builder.addCase(playerStartStop.fulfilled, (_state, _action) => {})
        builder.addCase(getCurrenlyPlayingTrack.fulfilled, (state, action) => {
            if (!action.payload) return

            const { track, isPlaying, artistId, artistName, progress }: CurrentlyPlayingResponse = action.payload

            state.player = {
                track, playing: isPlaying, 
                artist: {
                    id: artistId,
                    name: artistName,
                    uri: '',
                },
                progress,
                loaded: true
            }
        })
    },
});


const items = (state: RootState) => state.player
export const playerSelector = createSelector([items], (items) => items.player)

// const items = (state: RootState) => state.player
export const { updateSaved } = playerSlice.actions
export { playerStartStop, getCurrenlyPlayingTrack }
export default playerSlice.reducer