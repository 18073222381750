import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCodeBranch, faFile, faMagnifyingGlass, faPlay, faPause, faHome, faHeart, faCube, faXmark, faTableList, faTableColumns, faTableCellsLarge, faClockRotateLeft, faMusic, faBackward, faForward, faForwardStep, faBackwardStep } from '@fortawesome/free-solid-svg-icons'
import { store } from './Redux/Store'
import { Provider } from "react-redux";
import { faElementor } from '@fortawesome/free-brands-svg-icons'

library.add(
  faFile, faMagnifyingGlass, faCodeBranch, faPlay, faPause, faHome, faElementor, faMusic, faBackwardStep, faForwardStep,
  faCube, faHeart, faXmark, faTableList, faTableColumns, faTableCellsLarge, faClockRotateLeft,
  faBackward)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  // <React.StrictMode>
    
  // </React.StrictMode>

  <Provider store={store}>
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
